























































































import {Component, Prop} from "vue-property-decorator";
import ErrorMessageHandlerMixin from '@/misc/ErrorMessageHandler.mixins';
import {validationMixin} from 'vuelidate';
import {mixins} from 'vue-class-component';
import Application from "@/models/Application";
import Printd from 'printd';
import {ROUTE_LOGIN} from "@/router/routes";

@Component({
  mixins: [validationMixin],
  validations: {}
})
export default class PrintDialog extends mixins(ErrorMessageHandlerMixin) {
  /**
   * Current Application to show
   */
  @Prop({default: Application.parseFromObject({})})
  public application!: Application;

  /**
   * Should the Login Button be shown, to navigate to the login?
   */
  @Prop({default: false})
  public showLoginButton!: boolean;

  /**
   * Should the Button be shown as download button? (Send Download Event)
   */
  @Prop({default: false})
  public sendDownloadEvent!: boolean;

  /**
   * PrintD Instance to print a given element
   */
  private d: Printd = new Printd();

  /**
   * Style to print the element
   */
  private printStyle: string = `
    button {
      display: none;
    }

    #print-reference-title {
      display: none;
    }

    #user-name {
      margin-top: 1rem;
      font-size: 16px;
      font-weight: bold;
    }

    #company-information {
      display: block !important;
    }
  `;

  /**
   * Navigate to Login
   * @private
   */
  private async onNavigateToLoginPressed() {
    await this.$router.push({name: ROUTE_LOGIN});
  }

  /**
   * Print this Dialog
   * @private
   */
  private onPrintPressed() {
    // Send Download Event
    if (this.sendDownloadEvent) {
      this.$emit('download');
      return;
    }

    // Print this Dialog
    this.d?.print(
        this.$el as HTMLElement,
        [this.printStyle]
    );
  }

  private dismiss(reload: boolean = false) {
    this.$emit('closeDialog');
  }
}
